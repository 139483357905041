import React, { useContext } from "react";
import { useState } from "react";
import PremiumModal from "../components/PremiumModal";
import { RiUserLine } from "react-icons/ri";
import { userContext } from "../context/userContext";
import { httpReauest } from "../utils/httpRequest";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTonConnectModal } from "@tonconnect/ui-react";
const ProfilePage = ({ setLoading }) => {
  const [transaction, setTransaction] = useState(false);
  const { data, setData, id, setId } = useContext(userContext);
  const { state, open, close } = useTonConnectModal();
  const [premium, setPremium] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [rank, setRank] = useState(0);
  async function getUser() {
    setLoading(true);

    const response = await httpReauest("GET", `/user/${id}`, {}, {}).then(
      (e) => {
        if (e.status === 200) {
          setData(e.data.data);
        }
      }
    );

    setLoading(false);
  }
  async function getProfile() {
    const response = httpReauest(
      "GET",
      `/user/rewards/${data?.user?.telegramId}`,
      {},
      {}
    ).then((e) => {
      if (e.status) {
        setRewards(e?.data?.data?.rewards);
        setRank(e?.data?.data?.rank);
      }
    });
  }

  useEffect(() => {
    console.log(data?.user?._id);
    getProfile();
  }, []);
  return (
    <div className="flex flex-col items-center px-4">
      <div className="text-[16px] border-[14px] border-[#66CCFF] rounded-[25px] p-3 mt-5">
        <RiUserLine size={55} />
      </div>
      <h4 className="text-[24px] font-[600] mt-3">{data?.user?.username}</h4>
      <div className="flex flex-col mt-5 items-center bg-[#151515] border border-[#FFFFFF]/30 rounded-xl w-full">
        <div className="flex items-center justify-center gap-2 py-4 border-b border-[#000000] w-full">
          <span className="w-[30px] h-[30px] border-[6px] border-[#FFFFFF] rounded"></span>
          <div className="flex flex-col font-[600]">
            <p className="text-[24px] mb-[-10px]">{data?.user?.balance}</p>
            <p className="text-[16px] linear">SQUARE</p>
          </div>
        </div>
        <div className="flex justify-between w-full items-center p-3">
          <div className="flex items-center gap-1">
            <span className="w-[30px] h-[30px] border-[6px] border-[#FFFFFF] rounded"></span>
            <p className="text-[16px] font-[500]">RANK</p>
          </div>
          <div className="text-[16px] font-[500]"># {rank}</div>
        </div>
      </div>
      <Link
        to={"/friends"}
        className="flex justify-between mt-2 items-center p-3 bg-[#151515] border border-[#FFFFFF]/30 rounded-xl w-full"
      >
        <div className="flex items-center gap-1">
          <span className="w-[30px] h-[30px] border-[6px] border-[#FFFFFF] rounded"></span>
          <p className="text-[16px] font-[500]">FRIENDS</p>
        </div>
        <div className="text-[16px] font-[500]">
          {data?.invitedPepole?.length} >
        </div>
      </Link>
      <div className="grid w-full grid-cols-2 px-8 text-[13px] py-2 bg-[#151515] border gap-2 mt-2 border-[#FFFFFF]/30 rounded-xl">
        <button
          onClick={() => setTransaction(true)}
          className="bg-[#000000] rounded border border-[#FFFFFF]/30 py-1"
        >
          TRANSACTION
        </button>
        <button
          onClick={() => setPremium(true)}
          className="bg-[#000000] rounded border border-[#FFFFFF]/30 py-1"
        >
          PREMIUM
        </button>
      </div>
      {transaction && (
        <div className="border h-[220px] overflow-y-auto gap-4 flex flex-col items-center w-full border-[#FFFFFF] rounded-[20px] px-4 py-2 mt-5">
          {rewards?.map((e) => (
            <div className="flex w-full justify-between items-center border-b border-[#FFFFFF]/30">
              <div className="text-[16px]">REWARD FROM {e?.type}</div>
              <div className="text-[12px]">{e?.point} </div>
            </div>
          ))}
        </div>
      )}
      <span
        className={`absolute w-full z-10 duration-200 ${
          premium ? "left-0" : "left-[-100%]"
        }`}
      >
        <PremiumModal
          setPreactive={setPremium}
          getUser={getUser}
          setData={setData}
          id={id}
          data={data}
          open={open}
        />
      </span>
    </div>
  );
};

export default ProfilePage;
