import React from "react";
import { Link, useLocation } from "react-router-dom";
import EarnIcon from "../icons/EarnIcon";
import FriendsIcon from "../icons/FriendsIcon";
import HomeIcon from "../icons/HomeIcon";
import LeadersIcon from "../icons/LeadersIcon";
import ProfileIcon from "../icons/ProfileIcon";
import UserIcon from "../icons/UserIcon";

const Navbur = () => {
  const location = useLocation();
  return (
    <div className="grid grid-cols-5 text-[14px] justify-items-center fixed bottom-0 left-0 right-0 bg-[#151515] py-2">
      <Link to={"/"} className="flex flex-col items-center ">
        <HomeIcon location={location}/>
        <p className={`${location.pathname === '/' ? 'linear' :"text-[#FFFFFF]"}`}>Home</p>
      </Link>
      <Link to={"/leaders"} className="flex flex-col items-center ">
        <LeadersIcon location={location} />
        <p className={`${location.pathname === '/leaders' ? 'linear' :"text-[#FFFFFF]"}`}>Leaders</p>
      </Link>
      <Link to={"/friends"} className="flex flex-col items-center ">
        <FriendsIcon location={location} />
        <p className={`${location.pathname === '/friends' ? 'linear' :"text-[#FFFFFF]"}`}> Friends</p>
      </Link>
      <Link  to={"/earn"} className="flex flex-col items-center ">
        <EarnIcon location={location} />
        <p className={`${location.pathname === '/earn' ? 'linear' :"text-[#FFFFFF]"}`}>Earn</p>
      </Link>
      <Link to={"/profile"} className="flex flex-col items-center ">
        <ProfileIcon location={location}/>
        <p className={`${location.pathname === '/profile' ? 'linear' :"text-[#FFFFFF]"}`}>Profile</p>
      </Link>
    </div>
  );
};

export default Navbur;
