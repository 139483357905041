import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Countdown from "react-countdown";
import LeaderCart from "../components/LeaderCart";
import Loading from "../components/Loading";
import { userContext } from "../context/userContext";
import { httpReauest } from "../utils/httpRequest";

const LeadersPage = ({ loading, setLoading }) => {
  const [active, setActive] = useState("");
  const [rank, setRank] = useState(0);
  const [leaders, setLeaders] = useState([]);
  const [date, setDate] = useState();
  const { data, setData, id, setId } = useContext(userContext);
  async function getLeadersDaily() {
    setLoading(true);
    const response = await httpReauest("GET", `/user/daily/${id}`, {}, {}).then(
      (e) => {
        if (e.status === 200) {
          console.log(e);
          setActive("daily");
          setLeaders(e?.data?.data?.rewards);

          setDate(e?.data?.data?.endDate);
        }
      }
    );
    setLoading(false);
  }
  async function getProfile() {
    const response = httpReauest(
      "GET",
      `/user/rewards/${data?.user?.telegramId}`,
      {},
      {}
    ).then((e) => {
      if (e.status) {
        setRank(e?.data?.data?.rank);
      }
    });
  }
  useEffect(() => {
    getLeadersDaily();
    getProfile();
  }, []);
  async function getLeadersWeekly() {
    setLoading(true);
    const response = await httpReauest(
      "GET",
      `/user/weekly/${id}`,
      {},
      {}
    ).then((e) => {
      if (e.status === 200) {
        console.log(e);
        setActive("weekly");
        setLeaders(e?.data?.data?.rewards);

        setDate(e?.data?.data?.endDate);
      }
    });
    setLoading(false);
  }
  async function getLeadersMounthly() {
    setLoading(true);
    const response = await httpReauest(
      "GET",
      `/user/monthly/${id}`,
      {},
      {}
    ).then((e) => {
      if (e.status === 200) {
        console.log(e);
        setActive("monthly");
        setLeaders(e?.data?.data?.rewards);

        setDate(e?.data?.data?.endDate);
      }
    });
    setLoading(false);
  }
  const Completionist = () => <span>TimeEnd</span>;
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span className="text-[12px] font-[600] tracking-widest">
          {days > 0 && days + "D"} {hours}H
        </span>
      );
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full flex-col  mb-4 prompt text-[13px] font-[600] bg-[#151515] border px-2 py-3  rounded-b-3xl border-[#FFFFFF]/30 flex items-center justify-center">
        <div className="flex items-center justify-center w-full gap-3 border-b py-1 border-[#FFFFFF]/30">
          <button
            onClick={getLeadersDaily}
            className={`${
              active === "daily" ? " border-[#FFFFFF]" : "border-transparent"
            } border-b`}
          >
            DAILY{" "}
          </button>
          <button
            onClick={getLeadersWeekly}
            className={`${
              active === "weekly" ? " border-[#FFFFFF]" : "border-transparent"
            } border-b`}
          >
            WEEKLY
          </button>
          <button
            onClick={getLeadersMounthly}
            className={`${
              active === "monthly" ? " border-[#FFFFFF]" : "border-transparent"
            } border-b`}
          >
            MONTHLY
          </button>
        </div>
        <div className="flex w-full justify-center items-center gap-4 py-1">
          {active === "" && (
            <>
              <button>5 TON </button>
              <button>50 TON</button>
              <button>500 TON</button>
            </>
          )}
          {active === "daily" && (
            <>
              <button>5 TON </button>
              <Countdown date={new Date(date).getTime()} renderer={renderer} />
              <button>1 WINNER </button>
            </>
          )}
          {active === "weekly" && (
            <>
              <button>50 TON </button>
              <Countdown date={new Date(date).getTime()} renderer={renderer} />
              <button>10 WINNER</button>
            </>
          )}
          {active === "monthly" && (
            <>
              <button>100 TON </button>
              <Countdown date={new Date(date).getTime()} renderer={renderer} />
              <button>100 WINNER</button>
            </>
          )}
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full px-2 flex flex-col items-center gap-1 h-[70vh] overflow-y-auto">
          {leaders?.map((e, i) => (
            <LeaderCart leader={e} count={i} active={active} />
          ))}
        </div>
      )}
      <span className=" fixed bottom-[55px] left-0 right-0">
        <LeaderCart leader={data} count={rank} user active={active} />
      </span>
    </div>
  );
};

export default LeadersPage;
