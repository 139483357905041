import React, { useEffect } from "react";
import { useState } from "react";
import { RiUserLine } from "react-icons/ri";

const LeaderCart = ({ leader, count, user, active }) => {
  const [ton, setTon] = useState(0);
  function setcount() {
    setTon(0);
    if (active === "daily" && count === 0) {
      setTon(5);
    }
    if (active === "weekly" && count === 0) {
      setTon(25);
    }
    if (active === "weekly" && count === 1) {
      setTon(15);
    }
    if (active === "weekly" && count === 2) {
      setTon(4);
    }
    if (active === "weekly" && count >= 3 && count <= 9) {
      setTon(1);
    }
    if (active === "monthly" && count === 0) {
      setTon(40);
    }
    if (active === "monthly" && count === 1) {
      setTon(20);
    }
    if (active === "monthly" && count === 2) {
      setTon(10);
    }
    if (active === "monthly" && count >= 3 && count <= 99) {
      setTon(0.3);
    }
  }
  useEffect(() => {
    setcount();
  }, [active]);

  return (
    <div className={`flex px-6 py-2 w-full text-[12px] bg-[#151515]  border-[#FFFFFF]/30  items-center justify-between ${user ? "rounded-none border-t" : "rounded-[12px] border"}`}>
      <div className="flex items-center justify-center gap-1 ">
        <div className="text-[8px] border border-[#FFFFFF] rounded p-1">
          <RiUserLine size={20} />
        </div>
        <div className="flex flex-col items-start">
          <p>{leader?.user?.username}</p>
          <p>{leader?.totalPoints}</p>
        </div>
        {!user && ton > 0 && (
          <div className="flex justify-center items-center mx-2">
            <p>{ton}</p>
            <img className="w-[12px] h-[12px]" src="/img/ton.png" alt="" />
          </div>
        )}
      </div>
      <div>#{user ? count : count + 1}</div>
    </div>
  );
};

export default LeaderCart;
