import React from "react";

const LoadingCard = ({setStep}) => {
  return (
    <div className="flex flex-col items-start px-6">
      <div className="flex flex-col font-[600] items-end mb-20">
        <h2 className="square text-[48px] mb-[-20px]">SQUARE</h2>
        <p className="text-[20px] square ">ON TON</p>
      </div>
      <div className="square-1 flex justify-center items-center px-2">
        <h2 className="relative z-10 font-[600] square text-[48px]">SQUARE</h2>
      </div>
      <button onClick={()=>setStep(true)} style={{
          background:
            "linear-gradient(90deg, rgba(102,204,255,1) 0%, rgba(61,122,153,1) 100%)",
        }} className="rounded  py-2 text-[#101011] font-[600] absolute bottom-20 left-6 right-6">SQUARE CHECK</button>
    </div>
  );
};

export default LoadingCard;
