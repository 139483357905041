import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { userContext } from "../context/userContext";
import { httpReauest } from "../utils/httpRequest";
import { IoMdDoneAll } from "react-icons/io";
import Countdown from "react-countdown";
import Loading from "../components/Loading";
const EarnPage = ({ setLoading, loading }) => {
  const [active, setActive] = useState(false);
  const [tasks, setTasks] = useState([]);
  const { data, setData, id, setId } = useContext(userContext);
  const [taskLimted, setTasklimited] = useState([]);
  const [claim, setClaim] = useState(false);
  useEffect(() => {
    setTasks(data?.tasks);
    const filteredData = data?.taskLimted.filter(
      (item) => item.claim === false
    );
    console.log(filteredData);
    setTasklimited(filteredData);
  }, [loading]);
  async function handleTasks(e, i) {
    if (e?.done) {
      return i.preventDefault();
    }
    setLoading(true)
    const postId = await httpReauest(
      "POST",
      "/task",
      { userId: data?.user?._id, taskId: e?._id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        const getUser = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            if (e.status === 200) {
              setData(e.data.data);

              setId(id);
            }
          }
        );
      }
    });
    setLoading(false)
  }
  const Completionist = () => <span>TimeEnd</span>;
  const renderer = ({ days,hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      const dayHours = days * 24
      const allHours = dayHours + hours
      // Render a countdown
      return (
        <span className="text-[10px] font-[100] tracking-widest">
          {allHours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  async function handleLimited(e) {
    if (e?.done) {
      return;
    }
    const response = await httpReauest(
      "POST",
      "/task/limited",
      { userId: data?.user?._id, taskId: e?._id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        setLoading(true);
        const response = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            if (e.status === 200) {
              setData(e.data.data);
              setId(id);
            }
          }
        );
        setLoading(false);
      }
    });
  }
  async function handleComplate(e) {
    if (!e?.done) {
      return;
    }
    const response = await httpReauest(
      "POST",
      "/task/limited/claim",
      { userId: data?.user?._id, taskId: e?._id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        setLoading(true);
        const response = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            if (e.status === 200) {
              setData(e.data.data);
              setId(id);
            }
          }
        );
        setLoading(false);
      }
    });
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-full relative mb-8 prompt text-[24px] font-[500] bg-[#151515] border px-5 py-3  rounded-b-3xl border-[#FFFFFF]/30 flex items-center justify-center">
        <h3 className="linear">EARN SQUARE</h3>
      </div>
      <div className="w-full flex flex-col items-start px-6">
        <h2 className="text-[40px] font-[500]">TASKS</h2>
        <h3 className="text-[20px] font-[500]">GET REWARDS FOR </h3>
        <h3 className="text-[20px] font-[500]">COMPLETING QUESTS</h3>
      </div>
      <div className="grid w-[90%] grid-cols-2 px-8 text-[13px] py-2 bg-[#151515] border gap-2 mt-8 border-[#FFFFFF]/30 rounded-xl">
        <button
          onClick={() => {
            setActive(true);
          }}
          className={`bg-[#000000] rounded border  py-1 ${
            active ? "border-[#FFFFFF]" : "border-[#FFFFFF]/30"
          }`}
        >
          LIMITTED
        </button>
        <button
          onClick={() => {
            setActive(false);
          }}
          className={`bg-[#000000] rounded border  py-1 ${
            active ? "border-[#FFFFFF]/30" : "border-[#FFFFFF]"
          }`}
        >
          SQUARE
        </button>
      </div>
      <div className="flex flex-col w-[90%] border border-[#FFFFFF]/30 bg-[#151515] px-5 py-2 rounded-[12px] mt-5 h-[300px] overflow-y-auto">
        {!active ? (
          <>
            {tasks?.map((e) => (
              <div className="flex justify-between items-center border-b border-[#FFFFFF]/30 px-1 py-3">
                <span className="text-[16px] font-[500] ">{e?.content}</span>
                {e?.done ? (
                  <IoMdDoneAll />
                ) : (
                  <Link
                    target={"_blank"}
                    onClick={() => handleTasks(e)}
                    to={e?.url}
                    className="border border-[#FFFFFF] p-1 text-[12px] rounded-[7px]"
                  >
                    DO
                  </Link>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            {taskLimted?.map((e) => (
              <div className="flex justify-between items-center border-b border-[#FFFFFF]/30 px-1 py-3">
                <span className="text-[11px] font-[500] ">
                  {e?.content} {e?.point} SQUAREER (
                  <Countdown
                    onComplete={() => {
                      if (e?.done) {
                        setClaim(true);
                      } else if (!e?.done) {
                        const filteredData = taskLimted.filter(
                          (task) => task._id !== e._id
                        );
                        
                        setTasklimited(filteredData)
                        console.log("oncomplate")
                      }
                    }}
                    date={new Date(e?.end_date).getTime()}
                    renderer={renderer}
                  />
                  )
                </span>
                <div className="flex flex-col items-center">
                  {claim ? (
                    <button
                      onClick={() => handleComplate(e)}
                      className="border border-[#FFFFFF] p-1 py-2 text-[8px] rounded-[7px]"
                    >
                      CLAIM
                    </button>
                  ) : (
                    <button
                      onClick={() => handleLimited(e)}
                      className="border border-[#FFFFFF] p-1 py-1 text-[12px] rounded-[7px]"
                    >
                      DO
                    </button>
                  )}
                  <span className="text-[7px] text-[#FFFFFF]/50">
                    {e?.signed}/{e?.max}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default EarnPage;
