import React, { useContext } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import HomeCard from "../components/HomeCard";
import PremiumModal from "../components/PremiumModal";
import CheckIcon from "../icons/CheckIcon";
import UserIcon from "../icons/UserIcon";
import { userContext } from "../context/userContext";
import Loading from "../components/Loading";
import { httpReauest } from "../utils/httpRequest";
import { useEffect } from "react";
import Number from "../utils/Number";
import { useTonConnectModal, TonConnectButton } from "@tonconnect/ui-react";
import LoadingCard from "../components/LoadingCard";
const Home = ({ setLoading, loading, step, setStep }) => {
  const [preActive, setPreactive] = useState(false);
  const { data, setData, id, setId } = useContext(userContext);
  const [searchParams] = useSearchParams();
  const { state, open, close } = useTonConnectModal();

  async function getUser() {
    setLoading(true);
    if (id === "") {
      const userr = window?.Telegram.WebApp?.initDataUnsafe?.user;
      const user = searchParams.get("telegramId");
     if (user) {
      const response = await httpReauest("GET", `/user/${user}`, {}, {}).then(
        (e) => {
          if (e.status === 200) {
            setData(e.data.data);
            setId(user);
            console.log(e);
          }
        }
      );
     }else{
      const response = await httpReauest("GET", `/user/${userr}`, {}, {}).then(
        (e) => {
          if (e.status === 200) {
            setData(e.data.data);
            setId(userr);
            console.log(e);
          }
        }
      );
     }
    } else {
      const response = await httpReauest("GET", `/user/${id}`, {}, {}).then(
        (e) => {
          if (e.status === 200) {
            setData(e.data.data);
          }
        }
      );
    }
    setLoading(false);
  }
  useEffect(() => {
    getUser();
  }, []);
  const formatNumber = (num) => {
    // حذف سه رقم آخر
    let trimmedNum = Math.floor(num / 10);
    // تبدیل به رشته
    let numStr = trimmedNum.toString();

    // اضافه کردن نقطه بعد از رقم سوم از سمت راست
    if (numStr.length > 2) {
      return numStr.slice(0, -2) + "." + numStr.slice(-2, -1);
    } else if (numStr.length === 2) {
      return "0." + numStr; // برای اعداد دو رقمی
    } else {
      return "0.0" + numStr; // برای اعداد یک رقمی
    }
  };

  return (
    <>
      {step ? (
        <div className="flex flex-col items-center">
          <div className="w-full prompt text-[13px] font-[600] bg-[#151515] border px-5 py-3 rounded-b-3xl border-[#FFFFFF]/30 top-0 left-0 right-0 flex items-center justify-between">
            <div className="flex gap-2 items-center justify-start">
              <UserIcon />
              <p>{data?.user?.username}</p>
              {data?.user?.premium && <CheckIcon />}
            </div>
            <button
              onClick={() => {
                setPreactive(true);
              }}
              style={{
                background:
                  "linear-gradient(90deg, rgba(102,204,255,1) 0%, rgba(61,122,153,1) 100%)",
              }}
              className="text-[#101011] px-3 rounded-[5px] py-[3px] "
            >
              Primium
            </button>
          </div>
          {/* <button
     onClick={open}
     style={{
       background:
         "linear-gradient(90deg, rgba(102,204,255,1) 0%, rgba(61,122,153,1) 100%)",
     }}
     className="text-[14px] prompt text-[#101011] font-[600] mt-[50px] px-8 py-2 rounded-[17px]"
   >
     CONNECT WALLET
   </button> */}
          <div className="mt-[60px]">
            <TonConnectButton />
          </div>
          <div className="text-[48px] font-[600] mt-20 text-center">
            <p className="mb-[-20px]">
              {data?.user?.balance && formatNumber(data?.user?.balance)}k
            </p>
            <h2 className="square">SQUARE</h2>
          </div>
          <div className="absolute bottom-20  flex w-full gap-1 flex-col items-center px-4">
            {data?.tasks?.map((e) => (
              <HomeCard tasks={e} />
            ))}
          </div>
          <span
            className={`absolute w-full z-10 duration-200 ${
              preActive ? "left-0" : "left-[-100%]"
            }`}
          >
            <PremiumModal
              data={data}
              setData={setData}
              id={id}
              setPreactive={setPreactive}
              getUser={getUser}
              open={open}
            />
          </span>
        </div>
      ) : (
        <LoadingCard setStep={setStep} />
      )}
    </>
  );
};

export default Home;
